import { ApiGatewayPathBuilder, Env, IEmployerEnvironment } from 'shared-lib'

const env: Env = 'qa'
const pathBuilder = new ApiGatewayPathBuilder(env)

export const environment: IEmployerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBxgYkpbTWg3Tf0roc8Ec2bbquWN7lImPc',
    authDomain: 'cnect-qa-100.firebaseapp.com',
    projectId: 'cnect-qa-100',
    storageBucket: 'cnect-qa-100.appspot.com',
    messagingSenderId: '148414587373',
    appId: '1:148414587373:web:d40867c567bbb20e8ec7e4',
    databaseURL: 'https://cnect-qa-100.firebaseio.com',
    measurementId: 'G-VB0MB4FK1G',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-qa-100.cloudfunctions.net',
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    events: pathBuilder.build('events'),
    applicationHistory: pathBuilder.build('applicationHistory'),
    user: pathBuilder.build('user'),
    content: pathBuilder.build('content'),
    employer: pathBuilder.build('employer'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    messaging: pathBuilder.build('messaging'),
    virtualDialogue: pathBuilder.build('virtualDialogue'),
    access: pathBuilder.build('access'),
    jobsIntegration: pathBuilder.build('jobsIntegration'),
    notifications: pathBuilder.build('notifications'),
    customer: pathBuilder.build('customer'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false,
  themesEnabled: true,
  v2ReCaptcha: '6Lc2pOQcAAAAAI_oy89EVGVynt11riErd494oSOd',
  tenantId: 'B2B-USER-p37nj',
  algoliaAppId: 'XSN9B9X19Y',
  cdnCname: 'qa-content.cnected.com',
  basePlatformHost: 'qa-employer.cnected.com',
  candidateExternalJobUrl: 'https://qa-network.cnected.com/#/landing/job/',
  candidateEmailActionRoute: 'https://qa-network.cnected.com/#/auth/actions',
  careersPageBaseLink: 'https://qa-careers.cnected.com/',
  employerBaseUrl: 'https://qa-employer.cnected.com/',
  employerEmailSuccessRoute: 'https://qa-employer.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'sfIqysHX4k910Rq0K4r3',
  emailVerificationEmailTemplateId: '5KYG0zYnsdtR0DLtgjwv',
  betaMessageId: '1HV5MRdznzPO0ivgBbom',
  version: 'a7759c8',
  tenant: {
    appConfigBaseURL: 'https://qa-content.cnected.com/assets/bootstrap',
    defaultTenant: 'qa-employer.cnected.com',
  },
}
